import * as React from "react";
import {Typography} from "@material-ui/core";
import {Pagination, Skeleton} from "@material-ui/lab";
import {useListItems} from "../lists/hooks";
import {ProductItemHorizontal} from "../products/ProductItemHorizontal";
import {NumberParam, StringParam, useQueryParams} from "use-query-params";

export const UserTradesOffered = ({userId}) => {
    const [query, setQuery] = useQueryParams({
        g: StringParam,
        l: StringParam,
        page: NumberParam,
        q: StringParam,
        s: NumberParam,
        se: NumberParam,
        u: NumberParam,
    });
    const {isLoading, data, meta} = useListItems({...query, userId, listNames: [`OfferForTrade`], limit:24});
    const handlePageChange = (event, value) => {
        if (value !== query.page) {
            setQuery({page: value});
        }
    };

    if (isLoading) { return <Skeleton animation="pulse" height={500} variant="rect" /> }
    return data ? (
        <>
            <Typography className="pb-4">Total results: {meta.filter_count}</Typography>
            <ul className="flex flex-wrap -m-4">
                {data.map(item =>
                    <ProductItemHorizontal
                        hideUser={true}
                        item={{
                            ownerId: item.ownerId,
                            cardId: item.cardId,
                            quantity: item.quantity,
                            language: item.language,
                            conditionId: item.condition.id,
                            style: item.style[0],
                            signed: item.signed,
                            }}
                        key={item.id} />)}
            </ul>
            <Pagination className="mt-4" count={meta.page_count} onChange={handlePageChange} page={query.page} />
        </>) : `None`;
}
