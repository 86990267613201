import {useQueries, useQuery} from "react-query";
import PropTypes from "prop-types";
import {directusClient} from "../../hooks/useDirectus";
import {getList} from "./listsClient";
import cardAvailabilityLookup from "../../utils/card-availability-lookup";

function useListItems(filters) {
    const results = useQueries(filters.listNames.map((listName) => {
        return {
            enabled: !!filters.userId,
            queryKey: [`list`, filters, listName],
            queryFn: () => getList({...filters, listName}),
            select: data => {
                const items = data.items.reduce((accumulator, item) => {
                    if (item.created_by && item.card && item.card.set) {
                        accumulator.push({
                            ...item,
                            ownerId: item.created_by.id,
                            listName: listName,
                            cardId: item.card.id,
                            style: item.foiled ? `Foiled` : item.reverse_foiled ? `Reverse foiled` : `Standard`,
                            card: {
                                ...item.card,
                                availableStandard: cardAvailabilityLookup(item.card.available_standard, item.card.set.series.game.available_standard),
                                availableFoiled: cardAvailabilityLookup(item.card.available_foiled, item.card.set.series.game.available_foiled),
                                availableReverseFoiled: cardAvailabilityLookup(item.card.available_reverse_foiled, item.card.set.series.game.available_reverse_foiled),
                                availableSigned: cardAvailabilityLookup(item.card.available_signed, item.card.set.series.game.available_signed),
                                availableLanguages: (item.card.languages_override.length > 0 ? item.card.languages_override : item.card.set.series.game.languages),
                            }
                        })
                    }
                    return accumulator;
                }, []);
                return {items, meta:data.meta};
            }
        }
    }));
    const isLoading = results && results.some((list) => list.isLoading);
    const isSuccess = results && results.some((list) => list.isSuccess);
    const rows = isLoading || !isSuccess ? [] : results.flatMap((list) => list.data.items);
    let meta = {filter_count: 0, page_count: 1};
    !isLoading && isSuccess && results.forEach((queryResult) => {
        meta.filter_count += queryResult.data.meta.filter_count;
        meta.page_count = Math.ceil(meta.filter_count / queryResult.data.meta.limit);
        meta.page = filters.page;
    });
    return {isLoading, isSuccess, data: rows, meta, results};
}
useListItems.propTypes = {
    userId: PropTypes.number,
    listNames: PropTypes.arrayOf(PropTypes.string),
    cardId: PropTypes.number,
}

function useOfferedForTrade(userId, itemIds) {
    return useQuery([`list`, `offered_for_trade`, userId, itemIds], () => getItems(`offered_for_trade`, userId, itemIds));
}

function getItems(tableName, userId, itemIds) {
    // Currently all lists can be viewed publicly except My Collection
    if (typeof userId === `undefined`) {return Promise.resolve({data:[]})}
    const filters = {};
    itemIds && itemIds.length > 0 ? filters.id = {in: itemIds} : filters.created_by = {eq: userId};
    return directusClient.get(`/items/${tableName}`, {
        meta: [`result_count`],
        fields: [`id`,`quantity`, `condition`, `language`, `foiled`, `reverse_foiled`, `signed`, `card.id`, `card.name`, `card.subtitle`, `card.type`, `card.url_title`, `card.language`, `card.set.id`, `card.set.name`, `card.set.url_title`, `card.set.series.id`, `card.set.series.name`, `card.set.series.url_title`, `card.set.series.game.*`, `card.image.data.asset_url`],
        filter: {...filters},
        limit: 99999
    });

}

getItems.propTypes = {
    tableName: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired
};
useOfferedForTrade.propTypes = {
    userId: PropTypes.number.isRequired
};

export {useListItems, useOfferedForTrade}
